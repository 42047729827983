


























import {
  Component,
  Prop,
  Vue,
} from 'nuxt-property-decorator';
import { getCommentDateText } from '@devhacker/shared/utils/date';
import { Comment } from '~/types';

import CommentsList from '~/components/commentWidget/CommentList.vue';
import CommentCard from '~/components/CommentCard/CommentCard.vue';
import CommentsTitle from '~/components/commentWidget/CommentsTitle.vue';

@Component({
  components: {
    CommentsList,
    CommentCard,
    CommentsTitle,
  },
})
export default class CommentsWidget extends Vue {
  @Prop({
    required: true,
  }) readonly commentsList!: Array<Comment> | null

  @Prop({
    type: String,
    required: true,
  }) readonly title!: string

  @Prop({
    type: Object,
    required: true,
  }) readonly abTitles!: { [propName: string]: string }

  get isShowComments (): boolean {
    return (!!this.commentsList && !!this.commentsList.length);
  }

  getArticleTitle (item: Comment): string {
    // Подробнее см https://youtrack.lifehacker.ru/issue/LH-564
    const { post_ID, post_title } = item;
    const selectedTitle = this.abTitles[String(post_ID)];
    const title = post_title || '';
    return selectedTitle || title;
  }

  getTimePassed (date: string): string {
    return getCommentDateText(date);
  }
};
